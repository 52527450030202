import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class AppContextService {
  context = {
    vp: {},
    vollmacht: {
      anrede: null,
      anzahl_erinnerungen: null,
      buro_id: null,
      c_o: null,
      created_at: null,
      email: null,
      einwilligungen: null,
      geburtsdatum: null,
      id: null,
      key: null,
      key_valid_until: null,
      kundennummer: null,
      land: null,
      mobil: null,
      name: null,
      ort: null,
      plz: null,
      strasse: null,
      titel: null,
      ts_abgelehnt: null,
      ts_finished: null,
      ts_lastlogin: null,
      ts_letzte_erinnerung: null,
      ts_letzter_export: null,
      unterschrift: null,
      unterschrift_vm: null,
      updated_at: null,
      user_id: null,
      vollmachten: {
        maklervertrag: null,
        vertragsbestand: {
          vertraege: [],
        },
        datenschutz: {
          kommunikation: {
            brief: true,
            telefon: false,
            fax: false,
            email: false,
            whatsapp: false,
          },
          beratung_weitere_produkte: false,
          datenweitergabe_an_personen: null,
        },
        frei: {
          title: null,
          description: null,
          template: null,
          attachment: null,
        },
      },
      vollmachtstypen: null,
      vorname: null,
    },
    alreadySigned: false,
    download_available: false,
    key: null,
    models: null,
    consent: null,
    customizing: {
      styling: null,
      start_tile: null,
      start_text: null,
      logo: {
        name: null,
        mime: null,
        data: null,
      },
    },

    isPlatformDesktop: false,
    isPlatformMobile: false,
    isLoggedIn: false,
    isStammdatenVisited: false,
    isBankdatenVisited: false,
    isUmfangVisited: false,
    isKontaktVisited: false,
    isBestandsaufnahmeVisited: false,
    isDatenschutzVisited: false,
    isFreiVisited: false,
    isZusammenfassungVisited: false,
    isIduploadVisited: false,
    isEinwilligungVisited: false,
    isUnterschriftVisited: false,
    privatDisplayString: "",
    berufDisplayString: "",
    investmentDisplayString: "",
    kommunikationDisplayString: "",
    umfangDisplayString: "",
    kontaktDisplayString: "",
  };

  cfgBottomDrawer = {
    show: true,
    start: {
      show: false,
      index: 0,
    },
    stammdaten: {
      show: false,
      index: 0,
    },
    bankdaten: {
      show: false,
      index: 0,
    },
    umfang: {
      show: false,
      index: 0,
    },
    kontakt: {
      show: false,
      index: 0,
    },
    bestandsaufnahme: {
      show: false,
      index: 0,
    },
    datenschutz: {
      show: false,
      index: 0,
    },
    frei: {
      show: false,
      index: 0,
    },
    zusammenfassung: {
      show: false,
      index: 0,
    },
    idupload: {
      show: false,
      index: 0,
    },
    einwilligung: {
      show: false,
      index: 0,
    },
    unterschrift: {
      show: false,
      index: 0,
    },
  };

  initBottomDrawer() {
    const models = this.context.models;
    var pages = Array("start", "einwilligung", "unterschrift");

    for (let key in models) {

      if (key == "idupload") {
        console.log("idupload");
        pages = pages.concat(["idupload"]);
      }
      if (key == "md") {
        pages = pages.concat(["stammdaten", "zusammenfassung"]);
      }

      if (key == "bue") 
        /*if (
          this.context.models.bue &&
          this.context.models.bue.vtlist &&
          this.context.models.bue.vtlist.data
        ) */
          pages = pages.concat(["bestandsaufnahme", "zusammenfassung"]);

      if (key == "umfang") {
        pages = pages.concat(["umfang", "zusammenfassung"]);
      }
      if (key == "kontakt") {
        pages = pages.concat(["kontakt", "zusammenfassung"]);
      }
      if (key == "dkomm") {
        if (this.context.models.dkomm)
          pages = pages.concat(["stammdaten", "datenschutz", "zusammenfassung"]);
      }
    }

    var index = 0;
    for (let key in this.cfgBottomDrawer) {
      if (pages.indexOf(key) > -1) {
        if (this.context.alreadySigned && key == 'unterschrift') {
          this.cfgBottomDrawer[key].show = true;
          this.cfgBottomDrawer[key].index = 1;
        }
        if (!this.context.alreadySigned) {
          this.cfgBottomDrawer[key].show = true;
          this.cfgBottomDrawer[key].index = index;
          index++;
        }
      }
    }
    console.log("pages", pages);
  }

  getNextPage(actual: string) {
    console.log("Actual", actual);
    var found = false;
    for (let key in this.cfgBottomDrawer) {
      if (key == actual) {
        found = true;
      } else {
        if (found && this.cfgBottomDrawer[key].show) {
          console.log("next", key);
          return key;
        }
      }
    }
    return null;
  }

  constructor(public platform: Platform) {
    this.context.isPlatformDesktop = this.platform.is("desktop");
    this.context.isPlatformMobile =
      this.platform.is("mobileweb") || this.platform.is("mobile");
  }
}
