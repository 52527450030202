import { Component, OnInit, AfterViewInit, HostListener } from "@angular/core";
import { AppContextService } from "src/app/services/app-context.service";
import { Router } from "@angular/router";
import { CupertinoPane, CupertinoSettings } from "cupertino-pane";

@Component({
 selector: "bottom-drawer",
 templateUrl: "./bottom-drawer.component.html",
 styleUrls: ["./bottom-drawer.component.scss"],
})
export class BottomDrawerComponent implements OnInit, AfterViewInit {
 bottomDrawer: CupertinoPane;
 drawerOptions: CupertinoSettings;
 labelStammdaten = "Erfassung der persönlichen Daten.";
 labelBankdaten = "Erfassung der Bankverbindung.";
 labelUmfang = "Festlegung des gewünschten Betreuungsumfangs.";
 labelKontakt = "Festlegung der Kontaktwege.";
 labelBestandsaufnahme = "Erfassung der aktuellen Absicherungssituation.";
 labelDatenschutz = "Festlegung Kommunikation und Datenschutz";
 labelZusammenfasung = "Zusammenfassung der Eingaben.";
 labelEinwilligung = "Erteilung der Einwilligung.";
 labelUnterschrift = "Unterschrift und Abschluß.";
 labelDokumentabruf = "Abruf der Dokumentenmappe.";
 styling: any;

 constructor(public appContext: AppContextService, public router: Router) {}

 @HostListener("window:resize", ["$event"])
 onResize(event) {
  this.bottomDrawer.destroy();
  this.ngAfterViewInit();
 }

 ngOnInit() {
  this.styling = this.appContext.context.customizing.styling;
  this.drawerOptions = {
   breaks: {
    top: {
     enabled: true,
     height: this.appContext.context.isPlatformDesktop ? 260 : 650,
    },
    middle: {
     enabled: false,
    },
    bottom: {
     enabled: true,
     height: 46,
    },
   },
   initialBreak: "bottom",
   buttonClose: false,
   animationDuration: 400,
   clickBottomOpen: false,
  };
 }

 ngAfterViewInit() {
  this.bottomDrawer = new CupertinoPane(".bottom-drawer", this.drawerOptions);

  if (document.URL.indexOf("signature") === -1) {
   this.bottomDrawer.present();
   document.querySelector(".draggable").addEventListener("click", () => {
    if (this.bottomDrawer.currentBreak() === "top") {
     this.bottomDrawer.moveToBreak("bottom");
    } else {
     this.bottomDrawer.moveToBreak("top");
    }
   });
   document.querySelector(".bulltes-container").addEventListener("click", () => {
    if (this.bottomDrawer.currentBreak() === "top") {
     this.bottomDrawer.moveToBreak("bottom");
    } else {
     this.bottomDrawer.moveToBreak("top");
    }
   });
   if (this.appContext.context.isPlatformDesktop) document.querySelector(".pane").classList.add("desktop");
   else document.querySelector(".pane").classList.add("mobile");
  }
 }

 navigateToPage(pageName: string) {

    switch (pageName) {
      case "stammdaten":
       if (this.appContext.context.isLoggedIn && this.appContext.context.isStammdatenVisited) this.router.navigateByUrl("stammdaten");
       break;
      case "bankdaten":
       if (this.appContext.context.isLoggedIn && this.appContext.context.isBankdatenVisited) this.router.navigateByUrl("bankdaten");
       break;
      case "umfang":
       if (this.appContext.context.isUmfangVisited) this.router.navigateByUrl("umfang");
       break;
      case "bestandsaufnahme":
       if (this.appContext.context.isBestandsaufnahmeVisited) this.router.navigateByUrl("bestandsaufnahme");
       break;
      case "kontakt":
       if (this.appContext.context.isKontaktVisited) this.router.navigateByUrl("kontakt");
       break;
      case "datenschutz":
       if (this.appContext.context.isDatenschutzVisited) this.router.navigateByUrl("datenschutz");
       break;
      case "frei":
       if (this.appContext.context.isDatenschutzVisited) this.router.navigateByUrl("frei");
       break;
      case "zusammenfassung":
       if (this.appContext.context.isZusammenfassungVisited) this.router.navigateByUrl("zusammenfassung");
       break;
      case "einwilligung":
       if (this.appContext.context.isEinwilligungVisited) this.router.navigateByUrl("einwilligung");
       break;
      case "unterschrift":
       if (this.appContext.context.isEinwilligungVisited) this.router.navigateByUrl("unterschrift");
       break;
     }
   
     this.bottomDrawer.moveToBreak("bottom");
    
 }
}
